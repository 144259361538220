import { createSlice } from "@reduxjs/toolkit";
import { api_url } from "../resource/url";
import { commonVar, frontApiPaths } from "../common/constants";

const host=api_url;

export const statuses=Object.freeze({
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failed'
});
export const actionType=Object.freeze({
    LIST: 'list',
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    AUTHENTICATE: 'authinticate',
});

const eventSlice = createSlice({
    name: 'eventui',
    initialState: {
        eventList:[],
        status: statuses.PENDING,
        errorMsg:null,
        successMsg:null,
        actionName:actionType.LIST,
        isAuthenticate:false,
        eventId: null,
        eventInfo:[],
        activeEventList:[]
    },
    reducers: {
        initEvent(state, action){
            state.eventList = action.payload;
        },
        add(state, action){
            state.eventList.push(action.payload);
        },
        setStatus(state, action){
            state.status=action.payload;
        },
        setErrorMsg(state, action){
            state.errorMsg=action.payload;
        },
        setSuccessMsg(state, action){
            state.successMsg=action.payload;
        },
        setActionName(state, action){
            state.actionName=action.payload;
        },
        initEventInfo(state, action){
            state.eventInfo = action.payload;
        },
        setEventId(state, action){
            state.eventId=action.payload;
        },
        setactiveEventList(state, action){
            state.activeEventList=action.payload;
        },
    }
})

export const {initEvent, add, setStatus, setErrorMsg, setActionName, setSuccessMsg, initEventInfo, setEventId, setactiveEventList} = eventSlice.actions;
export default eventSlice.reducer;

export function setinitStatus(){
    return async function setinitStatusThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.PENDING));
            //dispatch(setIsAuthenticate(false));
            dispatch(setErrorMsg(""));
            dispatch(setSuccessMsg(""));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function initEventId(event_id){
    return async function initEventIdThunk(dispatch, getstate){
        try{
            dispatch(setEventId(event_id));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getEventById(event_id){
    return async function getEventByIdThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+frontApiPaths.GETEVENTBYID}/${event_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': commonVar.ACCESSVAL
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(initEventInfo(data.data.eventInfo));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getActiveEvent(offset, limit){
    return async function getActiveEventThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+frontApiPaths.GETACTIVEEVENTS}?offset=${offset}&limit=${limit}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': commonVar.ACCESSVAL
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(setactiveEventList(data.data.events));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}