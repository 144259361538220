import { createSlice } from "@reduxjs/toolkit";
import { api_url } from "../resource/url";
import { commonVar, frontApiPaths } from "../common/constants";

const host=api_url;

export const statuses=Object.freeze({
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failed'
});
export const actionType=Object.freeze({
    LIST: 'list',
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    AUTHENTICATE: 'authinticate',
});

const playerSlice = createSlice({
    name: 'playerui',
    initialState: {
        playerList:[],
        status: statuses.PENDING,
        errorMsg:null,
        successMsg:null,
        actionName:actionType.LIST,
        isAuthenticate:false,
        playerId: null,
        playerInfo:[],
        activePlayerList:[],
    },
    reducers: {
        initPlayer(state, action){
            state.playerList = action.payload;
        },
        add(state, action){
            state.playerList.push(action.payload);
        },
        setStatus(state, action){
            state.status=action.payload;
        },
        setErrorMsg(state, action){
            state.errorMsg=action.payload;
        },
        setSuccessMsg(state, action){
            state.successMsg=action.payload;
        },
        setActionName(state, action){
            state.actionName=action.payload;
        },
        initPlayerInfo(state, action){
            state.playerInfo = action.payload;
        },
        setplayerId(state, action){
            state.playerId=action.payload;
        },
        setActivePlayerList(state, action){
            state.activePlayerList=action.payload;
        },
    }
})

export const {initPlayer, add, setStatus, setErrorMsg, setActionName, setSuccessMsg, initPlayerInfo, setplayerId, setActivePlayerList, setUnassignPlayerList} = playerSlice.actions;
export default playerSlice.reducer;

///////Thunk Middleware///////
export function setinitStatus(){
    return async function setinitStatusThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.PENDING));
            //dispatch(setIsAuthenticate(false));
            dispatch(setErrorMsg(""));
            dispatch(setSuccessMsg(""));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
// export function changeTaskId(task_id){
//     return async function changeTaskIdThunk(dispatch, getstate){
//         try{
//             dispatch(setTaskId(task_id));
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
export function getPlayerById(player_id){
    return async function getPlayerByIdThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+frontApiPaths.GETPLAYERBYID}/${player_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': commonVar.ACCESSVAL
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(initPlayerInfo(data.data.playerInfo));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getActivePlayer(offset, limit, teamId){
    return async function getActivePlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+frontApiPaths.GETACTIVEPLAYER}?offset=${offset}&limit=${limit}&teamId=${teamId}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': commonVar.ACCESSVAL
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(setActivePlayerList(data.data.players));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}