import { createSlice } from "@reduxjs/toolkit";
import { api_url } from "../../resource/url";
import { APIPaths } from "../../common/constants";

const host=api_url;

export const statuses=Object.freeze({
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failed'
});
export const actionType=Object.freeze({
    LIST: 'list',
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    AUTHENTICATE: 'authinticate',
});

const videogallerySlice = createSlice({
    name: 'videogallery',
    initialState: {
        galleryList:[],
        status: statuses.PENDING,
        errorMsg:null,
        successMsg:null,
        actionName:actionType.LIST,
        isAuthenticate:false,
        galleryId: null,
        galleryInfo:[],
        activeGalleryList:[]
    },
    reducers: {
        initGallery(state, action){
            state.galleryList = action.payload;
        },
        add(state, action){
            state.galleryList.push(action.payload);
        },
        setStatus(state, action){
            state.status=action.payload;
        },
        setErrorMsg(state, action){
            state.errorMsg=action.payload;
        },
        setSuccessMsg(state, action){
            state.successMsg=action.payload;
        },
        setActionName(state, action){
            state.actionName=action.payload;
        },
        initGalleryInfo(state, action){
            state.galleryInfo = action.payload;
        },
        setGalleryId(state, action){
            state.galleryId=action.payload;
        },
        setActiveGalleryList(state, action){
            state.activeGalleryList=action.payload;
        },
    }
})

export const {initGallery, add, setStatus, setErrorMsg, setActionName, setSuccessMsg, initGalleryInfo, setGalleryId, setActiveGalleryList} = videogallerySlice.actions;
export default videogallerySlice.reducer;

///////Thunk Middleware///////
export function addGallery(gallery){
    return async function addGalleryThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.CREATEVIDEOGALLERY}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                },
                body: JSON.stringify(gallery), // body data type must match "Content-Type" header
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                //dispatch(add(task));
                dispatch(setActionName(actionType.ADD));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Gallery Added Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getGallery(cat_id){
    return async function getGalleryThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            dispatch(setGalleryId(null));
            var url=`${host+APIPaths.FETCHALLVIDEOGALLERY}`;
            if(cat_id)
                url=url+'?catId='+cat_id;                
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(initGallery(data.data.galleries));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function setinitStatus(){
    return async function setinitStatusThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.PENDING));
            //dispatch(setIsAuthenticate(false));
            dispatch(setErrorMsg(""));
            dispatch(setSuccessMsg(""));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
// export function changeTaskId(task_id){
//     return async function changeTaskIdThunk(dispatch, getstate){
//         try{
//             dispatch(setTaskId(task_id));
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
export function updateGallery(gallery, gallery_id){
    return async function updateGalleryThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.EDITVIDEOGALLERY}/${gallery_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                },
                body: JSON.stringify(gallery), // body data type must match "Content-Type" header
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                // getstate().users.galleryList.find(t => t.id === user_id).first_name=user.first_name;
                // getstate().users.galleryList.find(t => t.id === user_id).last_name=user.last_name;
                //dispatch(setinitStatus(tasklist));
                dispatch(initGalleryInfo(gallery));
                dispatch(setActionName(actionType.EDIT));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Gallery Updated Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function deleteGallery(gallery_id){
    return async function deleteGalleryThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.DELETEVIDEOGALLERY}/${gallery_id}`;
            // console.log(url);
            // console.log(getstate());
            const gallery_list=getstate().videogallery.galleryList;
            const result = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                //dispatch(add(task));
                const galleryLists=gallery_list.filter(gal => gal.id !== gallery_id);
                dispatch(initGallery(galleryLists));
                dispatch(setActionName(actionType.DELETE));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Gallery Deleted Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function initGalleryId(gallery_id){
    return async function initGalleryIdThunk(dispatch, getstate){
        try{
            dispatch(setGalleryId(gallery_id));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getGalleryById(gallery_id){
    return async function getGalleryByIdThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.GETVIDEOGALLERYBYID}/${gallery_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(initGalleryInfo(data.data.galleryInfo));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getActiveGallery(){
    return async function getActiveGalleryThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.GETACTIVEVIDEOGALLERY}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(setActiveGalleryList(data.data.galleries));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}