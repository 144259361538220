import { createSlice } from "@reduxjs/toolkit";
// const initialState = {
//     sidebarShow: true,
// }
  
//   const changeState = (state = initialState, { type, ...rest }) => {
//     switch (type) {
//       case 'set':
//         return { ...state, ...rest }
//       default:
//         return state
//     }
//   }
const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        sideBar: {sidebarShow: true},
        isAdminTheme: false
    },
    reducers: {
        changeState(state, action){
            state.sideBar = action.payload;
        },
        setAdminTheme(state, action){
            state.isAdminTheme = action.payload;
        }
    }
})

export const {changeState, setAdminTheme} = sidebarSlice.actions;
export default sidebarSlice.reducer;

///////Thunk Middleware///////
export function changeStateType(data){
    return async function changeStateTypeThunk(dispatch, getstate){
        dispatch(changeState(data));
    }
}
export function changeAdminTheme(val){
    return async function changeAdminThemeThunk(dispatch, getstate){
        dispatch(setAdminTheme(val));
    }
}