import { configureStore } from "@reduxjs/toolkit";
// import { applyMiddleware, legacy_createStore as createStore } from "redux";
//import thunk from "redux-thunk";
// import reducers from "./reducers";

// export const store = createStore(reducers, {}, applyMiddleware(thunk));
import userReducer from "./admin/userSlice";
import newsReducer from "./admin/newsSlice";
import eventReducer from "./admin/eventSlice";
import galleryReducer from "./admin/gallerySlice";
import teamReducer from "./admin/teamSlice";
import playerReducer from "./admin/playerSlice";
import tournamentReducer from "./admin/tournamentSlice";
import matchReducer from "./admin/matchSlice";
import gemcatReducer from "./admin/gemcatSlice";
import gemReducer from "./admin/gemSlice";
import activityReducer from "./admin/activitySlice";
import actgalleryReducer from "./admin/actgallerySlice";
import sidebarReducer from "./admin/sidebarSlice";
import videocatReducer from "./admin/videocatSlice";
import videogalleryReducer from "./admin/videogallerySlice";
import cmsReducer from "./admin/cmsSlice";
import unitReducer from "./admin/unitSlice";
import commitycatReducer from "./admin/commitycatSlice";
import commitysubcatReducer from "./admin/commitysubcatSlice";
import commitymemberReducer from "./admin/commitymemberSlice";
import awardReducer from "./admin/awardSlice";
import awardgalleryReducer from "./admin/awardgallerySlice";
import contactReducer from "./admin/contactSlice";
import groundReducer from "./admin/groundSlice";
import bannerReducer from "./admin/bannerSlice";
///////////For User End//////////////
import commonReducer from "./commonSlice";
import feventReducer from "./eventSlice";
import fnewsReducer from "./newsSlice";
import factgalleryReducer from "./actgallerySlice";
import fmatchReducer from "./matchSlice";
import fvideogalleryReducer from "./videogallerySlice";
import fteamReducer from "./teamSlice";
import fplayerReducer from "./playerSlice";
import fgalleryReducer from "./gallerySlice";
import factivityReducer from "./activitySlice";
import ftournamentReducer from "./tournamentSlice";
import fgemcatReducer from "./gemcatSlice";
import fgemReducer from "./gemSlice";
import fcmsReducer from "./cmsSlice";
import funitReducer from "./unitSlice";
import fcommitycatReducer from "./commitycatSlice";
import fcommitysubcatReducer from "./commitysubcatSlice";
import fcommitymemberReducer from "./commitymemberSlice";
import fawardReducer from "./awardSlice";
import fawardgalleryReducer from "./awardgallerySlice";
import fcontactReducer from "./contactSlice";
import fgroundReducer from "./groundSlice";
import fbannerReducer from "./bannerSlice";
//////End User End//////////////

export const store = configureStore({
  reducer: {
    // Define a top-level state field named `todos`, handled by `todosReducer`
    users: userReducer,
    news: newsReducer,
    event: eventReducer,
    gallery: galleryReducer,
    team: teamReducer,
    player: playerReducer,
    tournament: tournamentReducer,
    match: matchReducer,
    gemcat: gemcatReducer,
    gem: gemReducer,
    activity: activityReducer,
    actgallery: actgalleryReducer,
    sidebar: sidebarReducer,
    videocat: videocatReducer,
    videogallery: videogalleryReducer,
    cms: cmsReducer,
    unit: unitReducer,
    commitycat: commitycatReducer,
    commitysubcat: commitysubcatReducer,
    commitymember: commitymemberReducer,
    award: awardReducer,
    awardgallery: awardgalleryReducer,
    contact: contactReducer,
    ground: groundReducer,
    banner: bannerReducer,
    //////////For User End//////////
    common: commonReducer,
    eventui: feventReducer,
    newsui: fnewsReducer,
    actgalleryui: factgalleryReducer,
    matchui: fmatchReducer,
    videogalleryui: fvideogalleryReducer,
    teamui: fteamReducer,
    playerui: fplayerReducer,
    galleryui: fgalleryReducer,
    activityui: factivityReducer,
    tournamentui: ftournamentReducer,
    gemcatui: fgemcatReducer,
    gemui: fgemReducer,
    cmsui: fcmsReducer,
    unitui: funitReducer,
    commitycatui: fcommitycatReducer,
    commitysubcatui: fcommitysubcatReducer,
    commitymemberui: fcommitymemberReducer,
    awardui: fawardReducer,
    awardgalleryui: fawardgalleryReducer,
    contactui: fcontactReducer,
    groundui: fgroundReducer,
    bannerui: fbannerReducer
  },
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: false,
  //   }),
})