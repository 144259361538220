import { createSlice } from "@reduxjs/toolkit";
import { api_url } from "../../resource/url";
import { APIPaths } from "../../common/constants";

const host=api_url;

export const statuses=Object.freeze({
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failed'
});
export const actionType=Object.freeze({
    LIST: 'list',
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    AUTHENTICATE: 'authinticate',
});

const contactSlice = createSlice({
    name: 'contact',
    initialState: {
        contactList:[],
        status: statuses.PENDING,
        errorMsg:null,
        successMsg:null,
        actionName:actionType.LIST,
        isAuthenticate:false,
        contactId: null,
        contactInfo:[],
        activeContactList:[]
    },
    reducers: {
        initContact(state, action){
            state.contactList = action.payload;
        },
        add(state, action){
            state.contactList.push(action.payload);
        },
        setStatus(state, action){
            state.status=action.payload;
        },
        setErrorMsg(state, action){
            state.errorMsg=action.payload;
        },
        setSuccessMsg(state, action){
            state.successMsg=action.payload;
        },
        setActionName(state, action){
            state.actionName=action.payload;
        },
        initContactInfo(state, action){
            state.contactInfo = action.payload;
        },
        setContactId(state, action){
            state.contactId=action.payload;
        },
        setActiveContactList(state, action){
            state.activeContactList=action.payload;
        },
    }
})

export const {initContact, add, setStatus, setErrorMsg, setActionName, setSuccessMsg, initContactInfo, setContactId, setActiveContactList} = contactSlice.actions;
export default contactSlice.reducer;

///////Thunk Middleware///////
// export function addContact(contact){
//     return async function addContactThunk(dispatch, getstate){
//         try{
//             dispatch(setStatus(statuses.LOADING));
//             const url=`${host+APIPaths.CREATEGEMCAT}`;
//             //console.log(url);
//             const result = await fetch(url, {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'auth-token': localStorage.getItem('asc_token')
//                     //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
//                 },
//                 body: JSON.stringify(contact), // body data type must match "Content-Type" header
//             });
//             const data=await result.json();
//             //console.log(data);
//             if(data.status)
//             {
//                 //dispAlert("Note Added Successfully", "success");
//                 //dispatch(add(task));
//                 dispatch(setActionName(actionType.ADD));
//                 dispatch(setStatus(statuses.SUCCESS));
//                 dispatch(setErrorMsg(null));
//                 dispatch(setSuccessMsg("Contact Added Successfully"));
//             }
//             else
//             {
//                 //console.warn(JSON.stringify(data.error));
//                 dispatch(setStatus(statuses.FAILURE));
//                 dispatch(setErrorMsg(data.message));
//                 //dispatch(add([]));
                
//             }
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
export function getContact(){
    return async function getContactThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            dispatch(setContactId(null));
            const url=`${host+APIPaths.FETCHALLCONTACT}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(initContact(data.data.contacts));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function setinitStatus(){
    return async function setinitStatusThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.PENDING));
            //dispatch(setIsAuthenticate(false));
            dispatch(setErrorMsg(""));
            dispatch(setSuccessMsg(""));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
// export function updateContact(contact, contact_id){
//     return async function updateContactThunk(dispatch, getstate){
//         try{
//             dispatch(setStatus(statuses.LOADING));
//             const url=`${host+APIPaths.EDITGEMCAT}/${contact_id}`;
//             //console.log(url);
//             const result = await fetch(url, {
//                 method: 'PUT',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'auth-token': localStorage.getItem('asc_token')
//                     //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
//                 },
//                 body: JSON.stringify(contact), // body data type must match "Content-Type" header
//             });
//             const data=await result.json();
//             //console.log(data);
//             if(data.status)
//             {
//                 // getstate().users.contactList.find(t => t.id === user_id).first_name=user.first_name;
//                 // getstate().users.contactList.find(t => t.id === user_id).last_name=user.last_name;
//                 //dispatch(setinitStatus(tasklist));
//                 dispatch(initContactInfo(contact));
//                 dispatch(setActionName(actionType.EDIT));
//                 dispatch(setStatus(statuses.SUCCESS));
//                 dispatch(setErrorMsg(null));
//                 dispatch(setSuccessMsg("Contact Updated Successfully"));
//             }
//             else
//             {
//                 //console.warn(JSON.stringify(data.error));
//                 dispatch(setStatus(statuses.FAILURE));
//                 dispatch(setErrorMsg(data.message));
//                 //dispatch(add([]));
                
//             }
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
export function deleteContact(contact_id){
    return async function deleteContactThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.DELETECONTACT}/${contact_id}`;
            // console.log(url);
            // console.log(getstate());
            const cat_list=getstate().contact.contactList;
            const result = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                //dispatch(add(task));
                const contactLists=cat_list.filter(c => c.id !== contact_id);
                dispatch(initContact(contactLists));
                dispatch(setActionName(actionType.DELETE));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Contact Deleted Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
// export function initContactId(contact_id){
//     return async function initContactIdThunk(dispatch, getstate){
//         try{
//             dispatch(setContactId(contact_id));
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
// export function getContactById(contact_id){
//     return async function getContactByIdThunk(dispatch, getstate){
//         try{
//             dispatch(setStatus(statuses.LOADING));
//             const url=`${host+APIPaths.GETGEMCATBYID}/${contact_id}`;
//             //console.log(url);
//             const result = await fetch(url, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'auth-token': localStorage.getItem('asc_token')
//                     //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
//                 }
//             });
//             const data=await result.json();
//             //console.log(data);
//             if(data.status)
//             {
//                 //dispAlert("Note Added Successfully", "success");
//                 dispatch(initContactInfo(data.data.contactInfo));
//                 dispatch(setStatus(statuses.SUCCESS));
//                 dispatch(setErrorMsg(null))
//             }
//             else
//             {
//                 //console.warn(JSON.stringify(data.error));
//                 dispatch(setStatus(statuses.FAILURE));
//                 dispatch(setErrorMsg(data.message));
//                 //dispatch(add([]));
                
//             }
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
// export function getActiveContact(){
//     return async function getActiveContactThunk(dispatch, getstate){
//         try{
//             dispatch(setStatus(statuses.LOADING));
//             const url=`${host+APIPaths.GETACTIVEGEMCAT}`;
//             //console.log(url);
//             const result = await fetch(url, {
//                 method: 'GET',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'auth-token': localStorage.getItem('asc_token')
//                     //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
//                 }
//             });
//             const data=await result.json();
//             //console.log(data);
//             if(data.status)
//             {
//                 //dispAlert("Note Added Successfully", "success");
//                 dispatch(setActionName(actionType.LIST));
//                 dispatch(setActiveContactList(data.data.categories));
//                 dispatch(setStatus(statuses.PENDING));
//                 dispatch(setErrorMsg(null))
//             }
//             else
//             {
//                 //console.warn(JSON.stringify(data.error));
//                 dispatch(setStatus(statuses.FAILURE));
//                 dispatch(setErrorMsg(data.message));
//                 //dispatch(add([]));
                
//             }
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }