// import logo from './logo.svg';
import React, { Suspense } from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes,
  } from 'react-router-dom';
//import Login from '../containers/admin/Login';
import { NavigationPaths } from '../common/constants';
import '../scss/admin/style.scss'
////////CSS for FrontEnd Theme//////////
import '../assets/css/all.min.css'
import '../assets/css/rt-icons.css'
import '../assets/css/animate.min.css'
import '../assets/css/magnific-popup.css'
import '../assets/css/magnific.css'
import '../assets/css/swiper-bundle.min.css'
import '../assets/css/metisMenu.css'
import '../assets/css/rtsmenu.css'
import '../assets/css/variables/variable1.css'
import '../assets/css/main.css'

//////////////New Added on 06/06/2024///////////
import '../assets/css/font-awesome/5.10.0/css/all.min.css'
import '../assets/icons/bootstrap-icons.css'

import '../assets/lib/animate/animate.min.css'
import '../assets/lib/owlcarousel/assets/owl.carousel.min.css'

// import '../assets/css/bootstrap.min.css'
import '../assets/css/style.css'
import '../assets/css/custom-style.css'

import '../assets/css/normalize.min.css'

//////////////End New Added on 06/06/2024///////////

////////End CSS for FrontEnd Theme//////////
//import DefaultLayout from '../layout/admin/DefaultLayout';
// Containers
const Login = React.lazy(() => import('../containers/admin/Login'))
const DefaultLayout = React.lazy(() => import('../layout/DefaultLayout'))

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

function Navigation() {
  return (
    <Router>
      <Suspense fallback={loading}>
          <Routes>
            <Route exact path={NavigationPaths.HOME} element={<Login />} />
            {/* <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
            <Route path="*" name="Admin" element={<DefaultLayout />} />
          </Routes>
      </Suspense>
    </Router>
  );
}

export default Navigation;