import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
    name: 'common',
    initialState: {
        isStickyMenu:false,
        pageTitle: 'Home'
    },
    reducers: {
        setStickyMenu(state, action){
            state.isStickyMenu = action.payload;
        },
        setPageTitle(state, action){
            state.pageTitle = action.payload;
        }
    }
})

export const {setStickyMenu, setPageTitle} = commonSlice.actions;
export default commonSlice.reducer;

///////Thunk Middleware///////
export function changeStickyMenu(data){
    return async function changeStickyThunk(dispatch, getstate){
        dispatch(setStickyMenu(data));
    }
}
export function changePageTitle(title){
    return async function changePageTitleThunk(dispatch, getstate){
        dispatch(setPageTitle(title));
    }
}