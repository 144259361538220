import { createSlice } from "@reduxjs/toolkit";
import { api_url } from "../../resource/url";
import { APIPaths } from "../../common/constants";

const host=api_url;

export const statuses=Object.freeze({
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    FAILURE: 'failed'
});
export const actionType=Object.freeze({
    LIST: 'list',
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete',
    AUTHENTICATE: 'authinticate',
});

const playerSlice = createSlice({
    name: 'player',
    initialState: {
        playerList:[],
        status: statuses.PENDING,
        errorMsg:null,
        successMsg:null,
        actionName:actionType.LIST,
        isAuthenticate:false,
        playerId: null,
        playerInfo:[],
        activePlayerList:[],
        unassignPlayerList:[],
        countActivePlayer:0,
    },
    reducers: {
        initPlayer(state, action){
            state.playerList = action.payload;
        },
        add(state, action){
            state.playerList.push(action.payload);
        },
        setStatus(state, action){
            state.status=action.payload;
        },
        setErrorMsg(state, action){
            state.errorMsg=action.payload;
        },
        setSuccessMsg(state, action){
            state.successMsg=action.payload;
        },
        setActionName(state, action){
            state.actionName=action.payload;
        },
        initPlayerInfo(state, action){
            state.playerInfo = action.payload;
        },
        setplayerId(state, action){
            state.playerId=action.payload;
        },
        setActivePlayerList(state, action){
            state.activePlayerList=action.payload;
        },
        setUnassignPlayerList(state, action){
            state.unassignPlayerList=action.payload;
        },
        setActivePlayerCount(state, action){
            state.countActivePlayer=action.payload;
        },
    }
})

export const {initPlayer, add, setStatus, setErrorMsg, setActionName, setSuccessMsg, initPlayerInfo, setplayerId, setActivePlayerList, setUnassignPlayerList, setActivePlayerCount} = playerSlice.actions;
export default playerSlice.reducer;

///////Thunk Middleware///////
export function addPlayer(player){
    return async function addPlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.CREATEPLAYER}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                },
                body: JSON.stringify(player), // body data type must match "Content-Type" header
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                //dispatch(add(task));
                dispatch(setActionName(actionType.ADD));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Player Added Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getPlayer(team_id){
    return async function getPlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            dispatch(setplayerId(null));
            var url=`${host+APIPaths.FETCHALLPLAYER}`;
            if(team_id)
                url=url+'?teamId='+team_id;                
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(initPlayer(data.data.players));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function setinitStatus(){
    return async function setinitStatusThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.PENDING));
            //dispatch(setIsAuthenticate(false));
            dispatch(setErrorMsg(""));
            dispatch(setSuccessMsg(""));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
// export function changeTaskId(task_id){
//     return async function changeTaskIdThunk(dispatch, getstate){
//         try{
//             dispatch(setTaskId(task_id));
//         }
//         catch(error)
//         {
//             dispatch(setStatus(statuses.FAILURE));
//             dispatch(setErrorMsg("Something went wrong!"))
//             //dispatch(add([]));
//             console.log(error);
//             //dispatch(add(error));
//             //dispatch(addTodosFailed(JSON.stringify(error)));
//         }
//     }
// }
export function updatePlayer(player, player_id){
    return async function updatePlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.EDITPLAYER}/${player_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                },
                body: JSON.stringify(player), // body data type must match "Content-Type" header
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                // getstate().users.playerList.find(t => t.id === user_id).first_name=user.first_name;
                // getstate().users.playerList.find(t => t.id === user_id).last_name=user.last_name;
                //dispatch(setinitStatus(tasklist));
                dispatch(initPlayerInfo(player));
                dispatch(setActionName(actionType.EDIT));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Player Updated Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function deletePlayer(player_id){
    return async function deletePlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.DELETEPLAYER}/${player_id}`;
            // console.log(url);
            // console.log(getstate());
            const player_list=getstate().player.playerList;
            const result = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                //dispatch(add(task));
                const playerLists=player_list.filter(play => play.id !== player_id);
                dispatch(initPlayer(playerLists));
                dispatch(setActionName(actionType.DELETE));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Player Deleted Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function initPlayerId(player_id){
    return async function initPlayerIdThunk(dispatch, getstate){
        try{
            dispatch(setplayerId(player_id));
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getPlayerById(player_id){
    return async function getPlayerByIdThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.GETPLAYERBYID}/${player_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(initPlayerInfo(data.data.playerInfo));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getActivePlayer(){
    return async function getActivePlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.GETACTIVEPLAYER}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(setActivePlayerList(data.data.players));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function getUnAssignedPlayerByTeamId(team_id){
    return async function getUnAssignedPlayerByTeamIdThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.GETUNASSIGNPLAYERS}?teamId=${team_id}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActionName(actionType.LIST));
                dispatch(setUnassignPlayerList(data.data.players));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function assignPlayer(player){
    return async function assignPlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.ASSIGNPLAYERS}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                },
                body: JSON.stringify(player), // body data type must match "Content-Type" header
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                //dispatch(add(task));
                dispatch(setActionName(actionType.ADD));
                dispatch(setStatus(statuses.SUCCESS));
                dispatch(setErrorMsg(null));
                dispatch(setSuccessMsg("Player Assigned Successfully"));
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
export function countActivePlayer(){
    return async function countActivePlayerThunk(dispatch, getstate){
        try{
            dispatch(setStatus(statuses.LOADING));
            const url=`${host+APIPaths.COUNTACTIVEPLAYER}`;
            //console.log(url);
            const result = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'auth-token': localStorage.getItem('asc_token')
                    //'auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoxfSwiaWF0IjoxNjc2MDMyMTYxfQ.PGEA0Fwwzx19tqtK7Ok-etLQXC_BEJlm8EFP4Sf5C9M',
                }
            });
            const data=await result.json();
            //console.log(data);
            if(data.status)
            {
                //dispAlert("Note Added Successfully", "success");
                dispatch(setActivePlayerCount(data.data.totCount));
                dispatch(setStatus(statuses.PENDING));
                dispatch(setErrorMsg(null))
            }
            else
            {
                //console.warn(JSON.stringify(data.error));
                dispatch(setStatus(statuses.FAILURE));
                dispatch(setErrorMsg(data.message));
                //dispatch(add([]));
                
            }
        }
        catch(error)
        {
            dispatch(setStatus(statuses.FAILURE));
            dispatch(setErrorMsg("Something went wrong!"))
            //dispatch(add([]));
            console.log(error);
            //dispatch(add(error));
            //dispatch(addTodosFailed(JSON.stringify(error)));
        }
    }
}
